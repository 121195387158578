import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { checkCoupon } from '@services/coupon'
import { getURLParam } from '@helpers/general'

const SalesStoreCoupon = () => {
  useEffect(() => {
    let redirect = getURLParam('redirect') || '/'

    const params = new URLSearchParams(window?.location?.search || '')
    const paramsToAddToRedirect = new URLSearchParams()
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of params) {
      if (key !== 'cid') paramsToAddToRedirect.append(key, value)
    }
    if (paramsToAddToRedirect.toString()) redirect += `?${paramsToAddToRedirect.toString()}`

    checkCoupon().then(() => navigate(redirect))
  }, [])

  return <div />
}

export default SalesStoreCoupon
